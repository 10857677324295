<template>
  <div class="msg-rag" v-infinite-scroll="load">
    <div v-for="(i, index) in list" class="mox" :key="index">
      <div class="msg clear">
        <div :class="[i.userId == userId ? 'fr' : 'fl']">
          <div class="user-head" v-if="i.headUrl">
            <img :src="i.headUrl" alt="" />
          </div>
          <div class="user-head" v-else>
            <img src="@/assets/user.png" alt="" />
          </div>
        </div>
        <div class="user-msg" :class="[i.userId == userId ? 'fr' : 'fl']">
          <span :style="i.userId == userId ? 'float: right;' : ''">{{
            i.nickName ? i.nickName : "系统管理员"
          }}</span>
        </div>
      </div>
      <div class="clear">
        <div :class="i.userId == userId ? 'right' : 'left'">{{ i.record }}</div>
      </div>
    </div>
    <p v-if="loading" class="p-text">加载中...</p>
    <p v-if="!isLoading" class="p-text">没有更多的数据了</p>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { InfiniteScroll } from 'element-ui';
Vue.use(InfiniteScroll);
import { chatRecordRoomId } from '@/api/api';
export default {
  name: 'ChatRag',
  props: {
    activityCourseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      pageNum: 1,
      pageSize: 10,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    this.list = [];
    this.pageNum = 1;
    this.getchatRecordRoomId();
  },
  methods: {
    load() {
      if (this.list.length > 9 && this.isLoading) {
        this.loading = true;
        this.pageNum++;
        setTimeout(() => {
          this.getchatRecordRoomId();
        }, 200);
      }
    },
    getchatRecordRoomId() {
      chatRecordRoomId({
        roomId: this.activityCourseId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          const list = res.data.list;
          if (list.length > 0) {
            this.isLoading = true;
            for (let i = 0; i < list.length; i++) {
              this.list.push(list[i]);
            }
          } else {
            this.isLoading = false;
          }
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.msg-rag::-webkit-scrollbar {
  display: none;
}
.msg-rag::-moz-scrollbar {
  display: none;
}
.msg-rag::-ms-scrollbar {
  display: none;
}
// 身体
.msg-rag {
  height: ceil(484px - 90px);
  width: 100%;
  padding-bottom: 10px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
@import "~./chat.less";
</style>