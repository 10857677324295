<template>
  <div class="chat-box">
    <div class="header">
      <el-tabs v-model="activeName">
        <el-tab-pane label="直播间" name="first"></el-tab-pane>
        <el-tab-pane label="聊天室内容" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="activeName == 'first'">
      <chat-list :activityCourseId="activityCourseId"></chat-list>
    </div>
    <div v-else>
      <chat-rag :activityCourseId="activityCourseId"></chat-rag>
    </div>
    <div class="input-box clear">
      <div class="input-at fl">
        <input
          type="text"
          ref="sendMsg"
          v-model="contentText"
          :placeholder="placeholder"
          :disabled="disabledShow"
        />
      </div>
      <div :class="['btns']"></div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ChatList from './chat-list';
import ChatRag from './chat-rag';
import { chatRecordAdminNotice, chatRecordRoomId } from '@/api/api';
import { InfiniteScroll } from 'element-ui';
Vue.use(InfiniteScroll);
export default {
  name: 'Chat',
  props: {
    activityCourseId: {
      type: String,
      default: ''
    }
  },
  components: {
    ChatList,
    ChatRag
  },
  data() {
    return {
      activeName: 'first',
      placeholder: '当前为禁言状态',
      disabledShow: true,
      contentText: ''
    };
  },
  computed: {
    ...mapGetters(['token', 'userId'])
  }
};
</script>
<style lang="less" scoped>
.chat-box {
  background: #f4f4f4;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .header {
    width: 100%;
    height: 40px;
    background: #ffffff;
    overflow: hidden;
    box-sizing: border-box;
  }
  /deep/.el-tabs__item {
    width: 100%;
    text-align: center;
    font-size: 15px;
    &:hover {
      color: #00bd92;
    }
  }
  /deep/.el-tabs__item.is-active {
    color: #00bd92;
    font-weight: bold;
  }
  /deep/.el-tabs__active-bar {
    background: #00bd92;
    height: 3px;
  }
  .input-box {
    width: 100%;
    height: 50px;
    background: #0000;
    box-shadow: 0 0 5px #ccc;
    box-sizing: border-box;
    padding: 6px 20px;
    position: relative;
    .input-at {
      width: 250px;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 19px;
      overflow: hidden;
    }
    input {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      font-size: 16px;
      color: #363636;
    }
    .btns {
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      background: url("~@/assets/images/curriculum/fs.png") no-repeat 0px 0px;
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -11px;
      cursor: pointer;
      &.active {
        background: url("~@/assets/images/curriculum/fs-1.png") no-repeat 0px
          0px;
      }
    }
  }
}
</style>