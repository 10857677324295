<template>
  <div class="container broadxgplayer">
    <div class="container-fluid">
      <div class="title">{{ activityName }}</div>
      <div class="text">
        开课时间：{{
          courseStartTime
            | timeFormatter("{year}年{month}月{day}日 {hour}:{minute}:{second}")
        }}
      </div>
      <div class="videoBox flex">
        <div class="video-lf">
          <template v-if="courseStateShow">
            <div class="lf-playback">
              <xgplayer
                :imageUrl="imageUrl"
                :courseVideoUrl="courseVideoUrl"
                :activityCourseId="activityCourseId"
                :disableTitle="disableTitle"
                :proTime="proTime"
                :proActivityCourseId="proActivityCourseId"
                :disableProgress="disableProgress"
                :width="860"
                :height="484"
                @xgplayerIncident="xgplayerIncident"
              >
              </xgplayer>
            </div>
          </template>
          <template v-if="!courseStateShow">
            <div class="video-conet">
              <div class="conet-text">
                直播已结束，回放正在生成中，请耐心等待！
              </div>
            </div>
          </template>
        </div>
        <div class="chat">
          <chat :activityCourseId="activityCourseId"></chat>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Xgplayer from '@/components/xgplayer/index';
import Chat from './components/chat';
import { homePageCourseDetailActivityCourseDetail } from '@/api/api';
import { timeFormatter } from '@/utils/verify';
export default {
  name: 'Broadxgplayer',
  filters: { timeFormatter },
  components: {
    Xgplayer,
    Chat
  },
  data() {
    return {
      activityName: '',
      courseStartTime: '',
      courseStateShow: false,
      disableProgress: true,
      disableTitle: '',
      proTime: 0,
      proActivityCourseId: '',
      courseVideoUrl: '',
      activityCourseId: '', // 聊天室id
      imageUrl: ''
    };
  },
  created() {
    const {
      courseStartTime,
      activityName,
      courseState,
      imageUrl,
      activityCourseId,
      courseVideoUrl
    } = this.$route.query;
    this.activityName = activityName;
    this.courseStartTime = courseStartTime;
    this.imageUrl = imageUrl;
    this.courseVideoUrl = courseVideoUrl;
    this.activityCourseId = activityCourseId;
    if (courseState == 'PLAYBACK') {
      homePageCourseDetailActivityCourseDetail({
        activityCourseId: activityCourseId
      }).then((res) => {
        if (res.code == 200) {
          if (res.data.state == 'NOT_WATCH') {
            // 不能拖
            this.disableProgress = true;
            this.disableTitle = 'NOT_WATCH';
          } else {
            // WATCH 能拖
            this.disableProgress = false;
            this.disableTitle = 'WATCH';
          }
          this.proTime = parseInt(res.data.time);
          this.proActivityCourseId = String(res.data.activityCourseId);
          this.courseStateShow = true;
        }
      });
    } else if (courseState == 'OVER') {
      this.courseStateShow = false;
    }
  },
  methods: {
    // 开完视频后弹提示，关闭提示，刷新列表改变完成状态
    xgplayerIncident(e) {
      if (e.code == 200) {
        return;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.broadxgplayer {
  width: 100%;
  box-sizing: border-box;
  min-height: 830px;
  background: @TEXT-BG-888888;
  padding-top: 40px;
  .title {
    .fontSize(@TEXT-SIZE-24);
    line-height: 31px;
    color: @TEXT-COLOR-FFFFFF;
    margin-bottom: 10px;
  }
  .text {
    .fontSize(@TEXT-SIZE-14);
    line-height: 24px;
    color: @TEXT-COLOR-FFFFFF;
    margin-bottom: 20px;
    span {
      margin-left: 30px;
    }
  }
  .videoBox {
    width: 100%;
    .video-lf {
      width: 860px;
      height: 484px;
      background: #000;
    }
    .chat {
      width: 320px;
      height: 484px;
      background: @TEXT-BG-FFFFFF;
    }
    .video-conet {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .conet-text {
        color: #fff;
        font-size: 20px;
        border: 1px solid #fff;
        height: 50px;
        box-sizing: border-box;
        padding: 0 20px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
