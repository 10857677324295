<template>
  <div class="yan-xgplayer">
    <div id="mse"></div>
  </div>
</template>
<script>
import 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
import {
  userWatchInfoSendTime,
  userWatchInfoPutActivityCourse
} from '@/api/api';
var timer;
export default {
  name: 'Xgplayer',
  props: {
    imageUrl: {
      // 封面图
      type: String,
      default: ''
    },
    courseVideoUrl: {
      // 视频地址
      type: String,
      default: ''
    },
    activityCourseId: {
      // 轮询id
      type: String,
      default: ''
    },
    disableProgress: {
      // 是否拖动
      type: Boolean,
      default: true
    },
    disableTitle: {
      // 是否拖动返回 NOT_WATCH WATCH 没有用到
      type: String,
      default: ''
    },
    proActivityCourseId: {
      type: String,
      default: ''
    },
    proTime: {
      // 播放秒数
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 560
    },
    height: {
      type: Number,
      default: 315
    }
  },
  data() {
    return {
      requestFullscreen: false
    };
  },
  created() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.init();
      });
    }, 20);
  },
  methods: {
    init() {
      const _this = this;
      const player = new HlsJsPlayer({
        id: 'mse',
        autoplay: false,
        loop: false, // 循环播放
        url: _this.courseVideoUrl,
        poster: _this.imageUrl, // 封面图
        width: _this.width,
        height: _this.height,
        volume: 1,
        keyShortcut: 'off', // 键盘快捷键
        allowSeekAfterEnded: false,
        disableProgress: _this.disableProgress,
        lang: 'zh-cn'
      });
      player.once('ended', () => {
        player.config.disableProgress = false;
        if (_this.requestFullscreen) {
          player.exitFullscreen();
        }
        // 看完后请求后台改变可以拖动
        userWatchInfoPutActivityCourse({
          activityCourseId: parseInt(_this.activityCourseId)
        }).then((res) => {
          if (res.code == 200) {
            const title = res.data;
            var btnText;
            if (title.indexOf('已完成该活动') !== -1) {
              btnText = '去评价';
            } else {
              btnText = '好的';
            }
            _this
              .$periodPopup({ title: title, btnText: btnText })
              .then((res) => {
                if (res.code == 200) {
                  _this.$emit('xgplayerIncident', {
                    code: 200,
                    msg: '关闭弹出窗口成功',
                    data: title
                  });
                }
              });
          }
        });
      });
      player.once('requestFullscreen', () => {
        _this.requestFullscreen = true;
      });
      player.once('exitFullscreen', () => {
        _this.requestFullscreen = false;
      });
      player.currentTime = _this.proTime;
      // 从什么时候开始 定位秒。
      // 播放结束事件 请求后台服务器
      // 加载完后执行
      player.once('ready', () => {});
      player.on('play', () => {
        window.clearInterval(timer);
        timer = null;
        timer = setInterval(() => {
          userWatchInfoSendTime({
            activityCourseId: _this.activityCourseId,
            time: player.currentTime.toFixed(2)
          });
        }, 1000 * 30);
      });
      // 事件名称可以在上述查询
      player.on('seeked', () => {});
      player.on('pause', () => {
        // 暂停是清空定时器
        window.clearInterval(timer);
        timer = null;
      });
      // 播放器在销毁实例的时候会触发 destroy 事件
      player.on('destroy', () => {});
      // 只有点击播放后才会创建video标签拉取视频进行播放。如果想捕获创建video的时机，监听 complete 事件
      player.on('complete', () => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.yan-xgplayer {
  width: 100%;
  height: 100%;
  /deep/.xgplayer-replay-txt {
    color: transparent !important;
    text-align: left !important;
    margin-right: 25px !important;
    overflow: hidden !important;
  }
  /deep/.xgplayer-replay-txt:after {
    content: "重新播放";
    color: #fff;
  }
  /deep/.xgplayer-start {
    width: 190px;
    border-radius: 70px;
    margin-left: -95px;
  }
  /deep/.xgplayer-start:after {
    content: "开始学习";
    color: #ffffff;
    font-size: 20px;
    margin-left: 20px;
  }
}
</style>
